import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import BootstrapVue3 from 'bootstrap-vue-3'
import VueAxios from 'vue-axios'
import axios from 'axios'
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css"
import "./assets/css/magnific-popup.min.css";
import "./assets/css/slick.css";
import "./assets/css/default.css";
import "./assets/css/styles.css";
import "./assets/css/responsive.css";

import Notifications from '@kyvg/vue3-notification'
import router from "@/router";


createApp(App)
    .use(VueAxios, axios)
    .use(BootstrapVue3)
    .use(Notifications)
    .use(store)
    .use(router)
    .mount('#app')
