import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters: {

  },
  strict: true,
  plugins: [createPersistedState()],
})
