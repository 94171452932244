import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Main from "@/views/Main.vue";
import Privacy from "@/views/Privacy.vue";
import UserAgreemnet from "@/views/UserAgreemnet.vue";
import AmlPolicy from "@/views/AmlPolicy.vue";
import Login from "@/views/Login.vue";
import Registration from "@/views/Registration.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'main',
        component: Main,
    },
    {
        path: '/user-agreement',
        name: 'user-agreement',
        component: UserAgreemnet,
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy,
    },
    {
        path: '/aml',
        name: 'aml',
        component: AmlPolicy,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/registration',
        name: 'registration',
        component: Registration,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

