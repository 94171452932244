<template>
  <div id="app">
    <Menu></Menu>
    <notifications class="m-3"/>
    <router-view/>

    <footer class="footer-wrapper footer-layout1 position-relative">
      <div class="container" >
        <div class="footer-menu-area">
          <div class="row gy-4 justify-content-between align-items-center">

          </div>
        </div>
      </div>
      <div class="copyright-wrap text-center text-lg-start">
        <div class="container">
          <div class="row gy-3 justify-content-between align-items-center">
            <div class="col-lg-6 align-self-center">
              <p class="copyright-text">© 2024 <a href="/">Best prices</a> </p>
            </div>
            <div class="col-lg-6 text-lg-end">
              <ul class="footer-links">
                <li>
                  <a href="/user-agreement">
                    Пользовательское соглашение
                  </a>
                </li>
                <li>
                  <a href="/privacy">
                    Политика конфиденциальности
                  </a>
                </li>
                <li>
                  <a href="/aml">
                    AML политика
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>

import Menu from "@/components/Menu.vue";

export default {
  name: "App",
  computed: {
  },
  components: {
    Menu
  },
  data() {
    return {
    }
  },
  methods: {
  },
  async created() {
  }
}
</script>

